/** Open and Close Functionality for v8 Modal */
const v8modalContainer = document.querySelector('.v8Modal');
const v8closeButton = document.querySelector('.v8Modal__close');

function v8ModalOpen() {
  if (!v8modalContainer.classList.contains('active')) {
    v8modalContainer.classList.remove('out');
    v8modalContainer.classList.add('active');
  } else {
    return;
  }
}

function v8ModalClose() {
  if (v8modalContainer.classList.contains('active')) {
    v8modalContainer.classList.add('out');
    v8modalContainer.classList.remove('active');
  } else {
    return;
  }
}
